import React from "react";
import H from "./Headline";

const intro = {
    "/en/javascript/": (
        <React.Fragment>
            <p>hey paragraph1 is so funny!</p>
            <p>then afterwards I use the situation to bring some tough facts.</p>
            <p>When it gets silent then, I slowly walk backside out of the room.</p>
        </React.Fragment>
    ),
    "/de/javascript/": (
        <React.Fragment>
            <p>einfacher deutscher Satz.</p>
        </React.Fragment>
    )
};

const TagTemplateTop = ({ location, pageContext }) => (
    <React.Fragment>
        {/* breadcrumbs */}
        <H as="h1">{pageContext.tag.split("/")[1]}</H>
        {/* {intro[location.pathname]} */}
    </React.Fragment>
);

export default TagTemplateTop;
