import React from "react";

const tagBody = {
  "/en/javascript/": "super funny Body text",
  "/de/javascript/": "lustiger body text"
};

const TagTemplateBody = props => <React.Fragment>{tagBody[props.location.pathname]}</React.Fragment>;

export default TagTemplateBody;
