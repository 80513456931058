import React, { useContext } from 'react';
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import MainContent from "../components/MainContent";
import SEO from "../components/Seo";
import TagMetaData from "./tagMetaData.json";
import TagTemplateTop from "../components/TagTemplateTop";
import TagTemplateBody from "../components/TagTemplateBody";
import RelatedContentCardFlexBig from "../components/RelatedContentCardFlexBig";
import { UIContext } from '../components/UIContext';

const StyledCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  width: 100%;

  @media all and (max-width: 700px) {
    justify-content: center;
  }
`;

const TagTemplate = ({ location, data, pageContext }) => {
  const uicontext = useContext(UIContext);

  if (!data) return <p>Damn! Something went wrong. No data found!</p>;
  return (
    <Layout location={location} pageContext={pageContext}>
      <>
        <SEO
          title={
            TagMetaData[pageContext.tagName] && TagMetaData[pageContext.tagName][uicontext.language]
              ? TagMetaData[pageContext.tagName][uicontext.language].title
              : pageContext.tag.split("/")[1]
          }
          description={
            TagMetaData[pageContext.tagName] && TagMetaData[pageContext.tagName][uicontext.language]
              ? TagMetaData[pageContext.tagName][uicontext.language].description
              : `All posts related to ${pageContext.tag.split("/")[1]}`
          }
          alternateLangs={TagMetaData[pageContext.tagName] ? TagMetaData[pageContext.tagName].alternateLangs : null}
        />
        <MainContent maxWidth="1000">
          <TagTemplateTop location={location} pageContext={pageContext} />
          <StyledCardContainer>
            {data.allMdx &&
              data.allMdx.edges.map(({ node: post }) => (
                <RelatedContentCardFlexBig
                  key={post.id}
                  imageName={post.frontmatter.image.name}
                  relativePath={post.frontmatter.image.relativePath}
                  title={post.frontmatter.title}
                  link={`/${post.frontmatter.language}${post.fields.slug}`}
                  language={post.frontmatter.language}
                  date={post.frontmatter.publish}
                  timeToRead={post.timeToRead}
                  tags={
                    post.frontmatter.tags &&
                    post.frontmatter.tags.split(",").map(function (item) {
                      const [tag] = item.split(":");
                      return tag.trim();
                    })
                  }
                  description={post.frontmatter.description}
                />
              ))}
          </StyledCardContainer>
          {/* <TagTemplateBody location={uicontext.location} /> */}
        </MainContent>
      </>
    </Layout>
  );
};

export default React.memo(TagTemplate);

export const pageQuery = graphql`
  query TagQuery($tag: String!, $language: String!) {
    allMdx(
      filter: { 
        frontmatter: { 
          tags: { regex: $tag },
          language: { eq: $language }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            collection
            slug
          }
          frontmatter {
            language
            title
            titletag
            description
            publish(formatString: "DD. MMM YYYY")
            image {
              name
              relativePath
            }
            tags
          }
        }
      }
    }
  }
`;